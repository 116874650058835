import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatRippleModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
@NgModule({
  exports: [MatRippleModule, MatSidenavModule, MatToolbarModule, MatMenuModule, MatIconModule, MatButtonModule, MatDividerModule, MatBadgeModule, MatExpansionModule, MatTabsModule, MatInputModule, MatDialogModule]
})
export class AppMaterialModule { }
