<div class="fx-cv-sb">
    <div *ngIf="!onlyAwards">
        <div><b>Hotel la cabaña</b></div>
        <div><b>Playa Panteón, Puerto Ángel, Oaxaca, México.</b></div>
        <div><b>Tel. 01(958) 5843105 / 01(958) 5843448</b></div>
        <div><b>lacabanapuertoangel@hotmail.com</b></div>
    </div>
    <div class="fx-cv-ch" style="width: 100%;">
        <a style="margin-right: 16px;" class="mat-elevation-z2" matRipple href="https://www.booking.com/hotel/mx/la-cabaa-a.es.html" target="_blank">
            <img src="./assets/images/booking.jpg" width="90px" height="86px" alt="">
        </a>
        <a style="margin-right: 16px;" class="mat-elevation-z2" matRipple href="https://www.booking.com/hotel/mx/la-cabaa-a.es.html" target="_blank">
            <img src="./assets/images/nuevoBooking.jpeg" width="90px" height="86px" alt="">
        </a>
        <a class="mat-elevation-z2" style="margin-right: 16px;" matRipple href="https://www.gohotels.com/en-hotel/hotel-la-cabana-puerto-angel-mx.html" target="_blank">
            <img src="./assets/images/gohotels.jpg" width="90px" height="86px" alt="">
        </a>
        <a class="mat-elevation-z2" style="margin-right: 16px;" matRipple href="https://www.tripadvisor.com.mx/Hotel_Review-g153984-d157004-Reviews-La_Cabana_de_Puerto_Angel-Puerto_Angel_Southern_Mexico.html" target="_blank">
            <img src="./assets/images/tripadvisor.jpg" width="90px" height="86px" alt="">
        </a>
        <a class="mat-elevation-z2" style="margin-right: 16px;" matRipple href="https://www.facebook.com/hotel.puertoangel22" target="_blank">
            <img src="./assets/images/facebook.jpg" width="90px" height="86px" alt="">
        </a>
        <a class="mat-elevation-z2" style="margin-right: 16px;" matRipple>
            <img src="./assets/images/observaciondeballenas.jpg" width="90px" height="86px" alt="">
        </a>
        <a class="mat-elevation-z2" style="margin-right: 16px;" matRipple>
            <img src="./assets/images/petfriendly.jpg" width="90px" height="86px" alt="">
        </a>
    </div>
</div>