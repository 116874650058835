import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { HotelInterface } from 'src/app/interfaces/hotel/hotel.interface';
import { environment } from 'src/environments/environment';
interface ResponseIndexAPI {
  status: number;
  message: HotelInterface[]
}
interface ResponseGeneral {
  status: number;
  message: HotelInterface;
}
@Injectable({
  providedIn: 'root'
})
export class HotelService {

  name="hotel";
  hotel: HotelInterface;
  constructor(private _http: HttpClient, private _router: Router) { }

  getById(id: String): Observable<HotelInterface>{
    return this._http.get(environment.server+this.name+"/"+id).pipe(map((resp: ResponseGeneral)=>{
      if(resp.message){
        if(!resp.message.politics){
          resp.message.politics = [];
        }
        return resp.message;
      }
      return null;
    }))
  }

  getHotel(){
    return this._http.get(environment.server+this.name).pipe(map((resp: ResponseIndexAPI)=>{
      if(resp.message){
        if(resp.message.length>0){
          if(!resp.message[0].politics){
            resp.message[0].politics = [];
          }
          console.log('--->location ref: ', window.location.href);
          if(!environment.production){
            for(let h of resp.message){
              if(h.id === "5fe1ffca46f0600fec51463d"){
                this.hotel = h;
                break;
              }
            }
          } else{
              console.log('--->Si entro');
              for(let h of resp.message){
                if(h.id === "5fe1ffca46f0600fec51463d"){
                  this.hotel = h;
                  break;
                }
              }
          }



          return this.hotel;
        }
        return null;
      }
      return null;
    }))
  }

}
