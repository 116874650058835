import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  theme: String = "craner-theme";
  constructor() { }

  setTheme(themeName: String){
    this.theme = themeName;
  }

  get themeId(): any{
    return this.theme;
  }
  
}
