import { SharedGlobalModule } from './modules/shared-global/shared-global.module';
import { PipesModule } from './modules/pipes/pipes.module';
import { AppMaterialModule } from './app-material.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavigationComponent } from './components/navigation/navigation.component';
import { InterceptorService } from './services/interceptor.service';
import { SelectThemeComponent } from './components/select-theme/select-theme.component';
import { CustomToolbarComponent } from './components/navigation/custom-toolbar/custom-toolbar.component';
import { ToolbarComponent } from './components/navigation/toolbar/toolbar.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    SelectThemeComponent,
    CustomToolbarComponent,
    ToolbarComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    SharedGlobalModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
