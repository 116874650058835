import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatRippleModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  exports: [MatRippleModule, MatToolbarModule, MatMenuModule, MatIconModule, MatButtonModule, MatDividerModule, MatBadgeModule,MatDialogModule, MatToolbarModule]
})
export class SharedGlobalMaterialModule { }
