<div mat-dialog-title>
    <div><b>Temas disponibles</b></div>
</div>
<mat-dialog-content class="mat-typography">
    <div *ngFor="let t of themes">
        <div class="mat-elevation-z5 bg-main mb-2 pd-general">
            <div class="fx-cv-sb mb-4">
                <div style="font-size: 18px; font-weight: bold">{{t}}</div>
                <div>
                    <button mat-flat-button (click)="themeService.setTheme(t)" [disabled]="themeService.themeId == t">SELECCIONAR</button>
                </div>
            </div>
            <div class="fx-cv-ch" [ngClass]="t">
                <div class="bg-p-light fx-cv-ch" style="height: 50px; width: 50px">T</div>
                <div class="bg-primary fx-cv-ch" style="height: 50px; width: 50px">T</div>
                <div class="bg-p-dark fx-cv-ch" style="height: 50px; width: 50px">T</div>
                <div class="bg-a-light fx-cv-ch" style="height: 50px; width: 50px">T</div>
                <div class="bg-accent fx-cv-ch" style="height: 50px; width: 50px">T</div>
                <div class="bg-a-dark fx-cv-ch" style="height: 50px; width: 50px">T</div>
            </div>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>CERRAR</button>
</mat-dialog-actions>