<div mat-dialog-title>
    <div><b>{{data.title}}</b></div>
</div>
<mat-dialog-content class="mat-typography">
    <div style="padding: 16px;">
        <div style="text-align: center" *ngIf="data.question"><b>{{data.question}}</b></div>
        <div class="fx-cv" *ngIf="data.message">
            <div style="font-size: 36px">
                <mat-icon color="warn" inline>info</mat-icon>
            </div>
            <div><b>{{data.message}}</b></div>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button color="accent" (click)="submit()">{{data.txtConfirm!=null?data.txtConfirm:'ENTERADO'}}</button>
</mat-dialog-actions>