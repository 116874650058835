import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/internal/operators/tap';
import { throwError } from 'rxjs/internal/observable/throwError';
import { OriginCompanyService } from './origin-company.service';

@Injectable({
	providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
	constructor(private _origin: OriginCompanyService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let cloned: HttpRequest<any> = req;
		let setToken = true;
		if (cloned.method === 'POST' || cloned.method === 'PUT') {
			this.shiftDates(cloned.body);
		}
		if (req.url.includes('login')) {
			setToken = false;
		} else{
			setToken = true;
		}
		if (setToken) {
			console.log('origin: ', this._origin.nameOrigin);
			cloned = req.clone({
				headers: req.headers.set('Empresa', "lacabana")
			});

		}
		return next.handle(cloned).pipe(tap((event: any) => {
			if (event.status === 200) {
				if (event.body.message) {
					// console.log('Correcto: ', event.body.message);
				} else {
				}
			}
		}, (err) => {
			console.log('error: ', err);
			let error = { message: '??', title: '??' };
			if (err.status === 400) {
				error = { message: 'Informacion invalida o incompleta', title: '400 Bad request' };
			} else if (err.status === 401) {
				if (req.url.indexOf('login') > -1) {
					error = { message: 'Usuario o contraseña incorrectos', title: '' };
				} else {
					error = { message: 'No autorizado o sesión expirada', title: '401 Unauthorized' };
				}
			} else if (err.status === 403) {
				error = { message: '403 Forbidden', title: '403 Forbidden' };
			} else if (err.status === 404) {
				error = { message: '404 Not found', title: '404 Not found' };
			} else if (err.status === 410) {
				error = { message: '410 Gone', title: '410 Gone' };
			} else if (err.status === 500) {
				error = { message: '500 Server error', title: '500 Server error' };
			} else if (err.status === 520) {
				error = { message: '520 Altan API Error', title: '520 Altan API Error' };
			} else if (err.status === 0) {
				error = { message: '0 Try later. is the server down?', title: '0 Server request error' };
			} else {
			}
			if (err.error) {
				if (err.error.error) {
					error.message = err.error.error;
				}
				if (err.error._errors) {
					for (const e of err.error._errors) {
						console.log('Error: ', e.error);
					}
				}
				if (err.error.errores) {
					for (const e of err.error.errores) {
						console.log('Error: ', e.error);
					}
				}
				if (err.error.descripcion) {
					error.message = err.error.description;
				}
			}
			if (error.message != '') {
				console.log('Error: ', error.title + ' message: ', error.message);
			}
			return throwError(error.message);
		}));
	}

	shiftDates(body: any) {
		if (body === null || body === undefined) {
			return body;
		}

		if (typeof body !== 'object') {
			return body;
		}

		for (const key of Object.keys(body)) {
			const value = body[key];
			if (value instanceof Date) {
				body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes()
					, value.getSeconds()));
				console.log('-----shiftDates key: ');
			} else if (typeof value === 'object') {
				this.shiftDates(value);
			}
		}
	}
}
