import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedGlobalMaterialModule } from './shared-global-material.module';
import { FooterComponent } from './footer/footer.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';



@NgModule({
  declarations: [FooterComponent, ConfirmDialogComponent],
  imports: [
    CommonModule,
    SharedGlobalMaterialModule
  ],
  exports: [FooterComponent, ConfirmDialogComponent]
})
export class SharedGlobalModule { }
