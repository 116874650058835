import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class BreakPointService {

  constructor(private _breakpointObserver: BreakpointObserver) { }

  public isMovil(): Observable<boolean> {
		return this._breakpointObserver
			.observe(Breakpoints.Handset)
			.pipe(map((result) => result.matches));
	}
	public isTablet(): Observable<boolean> {
		return this._breakpointObserver
			.observe(Breakpoints.Small)
			.pipe(map((result) => result.matches));
	}
	public isWeb(): Observable<boolean> {
		return this._breakpointObserver
			.observe(Breakpoints.Web)
			.pipe(map((result) => result.matches));
	}
}
