import { BreakPointService } from 'src/app/services/util/break-point.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HotelInterface } from './../../interfaces/hotel/hotel.interface';
import { HotelService } from './../../services/api/hotel.service';
import { Component, OnInit } from '@angular/core';
import { OriginCompanyService } from 'src/app/services/origin-company.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  isLoading: boolean;
  hotel: HotelInterface;


  constructor(private _router: Router, private _originService: OriginCompanyService, private _hotelService: HotelService, public hotelService: HotelService, private _activateRoute: ActivatedRoute, public breakpoint: BreakPointService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this._hotelService.getHotel().subscribe((resp: HotelInterface) => {
      this.isLoading = false;

      this.hotel = resp;
    }, (e) => {
      this.isLoading = false;
    });

  }

}
