import { ThemeService } from './../../services/util/theme.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-theme',
  templateUrl: './select-theme.component.html',
  styleUrls: ['./select-theme.component.scss']
})
export class SelectThemeComponent implements OnInit {
  themes = ["crane-theme"]
  constructor(public themeService: ThemeService, public dialogRef: MatDialogRef<SelectThemeComponent>) { }

  ngOnInit(): void {
  }

  selectTheme(i: number){
    this.themeService.setTheme(this.themes[i]);
  }

  submit() {
    this.dialogRef.close(true);
  }
}
