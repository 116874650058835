import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SelectThemeComponent } from 'src/app/components/select-theme/select-theme.component';
import { BreakPointService } from 'src/app/services/util/break-point.service';
import { HotelService } from 'src/app/services/api/hotel.service';
import { HotelInterface } from 'src/app/interfaces/hotel/hotel.interface';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Output()
  evToggle: EventEmitter<boolean>;
  hotel: HotelInterface;

  constructor(private _router: Router, public breakPoint: BreakPointService, private _matDialog: MatDialog, public _hotel: HotelService) { 
    this.evToggle = new EventEmitter<boolean>();
    this.hotel = this._hotel.hotel;
  
  }

  ngOnInit(): void {
    console.log('App toolbar->prueba');
  }

  emitToggle() {
    this.evToggle.emit(true);
  }

  openThemes(){
    this._matDialog.open(SelectThemeComponent,{
      disableClose: true
    });
  }

}
