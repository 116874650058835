<mat-sidenav-container *ngIf="hotel" class="ct-sidenav">
    <mat-sidenav #drawer class="bg-drawer sidenav" mode="over">
        <div style="height: 10px;" class="bg-primary"></div>
        <div style="height: 180px;" class="pd-general bg-header-drawer">
            <div class="fx-cv-ch">
                <img [src]="hotel.urlLogoSelected | image" width="100%" height="100px;" style="object-fit: contain;" />
            </div>
            <div class="fx-cv-ch">
                <div class="txt-subtitle" style="line-height: 30px;">{{hotel.nameSpanish}}</div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="bg-body-drawer" style="padding: 8px">
            <div style="display: flex; align-items: center; justify-content:center;">
                <img src="https://pngimg.com/uploads/whatsapp/whatsapp_PNG95158.png" alt="whatsapp" width="36" height="36" style="object-fit: cover;">
                <div style="margin-left:16px;"><b>+52 958 584 3105 </b></div>
            </div>
            <div style="display: flex; align-items: center; justify-content:center; padding: 8px;">
                <a style="text-decoration: none; font-weight: bold;" href="https://www.facebook.com/hotel.puertoangel22" target="_blanck"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/768px-Facebook_icon_2013.svg.png" alt="whatsapp" width="36" height="36" style="object-fit: cover;"></a>
            </div>

            <div class="ct-menu" matRipple routerLink="/home" routerLinkActive="select-menu" (click)="drawer.toggle()">
                <div>
                    Inicio
                </div>
            </div>
            <div class="ct-menu" matRipple routerLink="/hotel" routerLinkActive="select-menu" (click)="drawer.toggle()">
                <div>
                    Hotel
                </div>
            </div>
            <div class="ct-menu" matRipple routerLink="/roomTypes" routerLinkActive="select-menu" (click)="drawer.toggle()">
                <div>
                    Habitaciones
                </div>
            </div>
            <div class="ct-menu" matRipple routerLink="/locationBlog" routerLinkActive="select-menu" (click)="drawer.toggle()">
                <div>
                    Turismo
                </div>
            </div>
            <div class="ct-menu" matRipple routerLink="/contactUS" routerLinkActive="select-menu" (click)="drawer.toggle()">
                <div>
                    Contáctanos
                </div>
            </div>
            <!--

<div class="ct-menu" matRipple routerLink="/pandemicInfo" routerLinkActive="select-menu" (click)="drawer.toggle()" (click)="drawer.toggle()">
                <div>
                    Covid-19
                </div>
            </div>
            -->

        </div>
    </mat-sidenav>
    <mat-sidenav-content class="ct-drawer" style="height: 100vh;">
        <div style="width: 100%;" *ngIf="hotel">
            <app-toolbar (evToggle)="drawer.toggle()" *ngIf="(breakpoint.isMovil() | async) || (breakpoint.isTablet() | async)"></app-toolbar>
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
