<mat-toolbar color="primary">
    <mat-toolbar-row style="height: 56px;">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="emitToggle()"
            *ngIf="(breakPoint.isMovil() | async) || (breakPoint.isTablet() | async)">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <div class="txt-subtitle" style="font-size: 20px; max-width: 200px; line-height: 36px;">{{hotel.nameSpanish}}</div>
        <div class="fx-spacer"></div>
        <div class="fx-cv" *ngIf="!(breakPoint.isMovil() | async) && !(breakPoint.isTablet() | async)">
            <div style="margin: 0px 4px !important;" class="ct-menu" matRipple routerLink="/home"
                routerLinkActive="select-menu">
                <div>
                    Inicio
                </div>
            </div>
            <div style="margin: 0px 4px !important;" class="ct-menu" matRipple routerLink="/hotel"
                routerLinkActive="select-menu">
                <div>
                    Hotel
                </div>
            </div>
            <div style="margin: 0px 4px !important;" class="ct-menu" matRipple routerLink="/locationBlog"
                routerLinkActive="select-menu">
                <div>
                    Turismo
                </div>
            </div>
            <div style="margin: 0px 4px !important;" class="ct-menu" matRipple routerLink="/contactUS"
                routerLinkActive="select-menu">
                <div>
                    Contáctanos
                </div>
            </div>
            <!--
<div style="margin: 0px 4px !important;" class="ct-menu" matRipple routerLink="/pandemicInfo"
                routerLinkActive="select-menu">
                <div>
                    Covid-20
                </div>
            </div>
            -->

        </div>
    </mat-toolbar-row>
</mat-toolbar>
