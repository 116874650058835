import { Injectable } from '@angular/core';
import { OriginInterface } from '../interfaces/origin.interface';

@Injectable({
  providedIn: 'root'
})
export class OriginCompanyService {
  origin: String="";
  constructor() { }

  get nameOrigin(){
    return this.origin;
  }

  set putOrigin(origin: String){
    this.origin = origin;
    console.log('origin in OriginCompanyService: '+this.origin);
  }
}
