import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(urlImage: String): String {
    console.log('url: ', environment.server+urlImage);
    if(urlImage.includes('http')){
      return urlImage;
    }
    return environment.server+urlImage;
  }

}
